import * as $ from 'jquery';
import { dungeonData } from './dungeoneer';
// @ts-ignore
global.$ = $;
import 'maphilight';
function roomNameFixer(name) {
    return name.replace(" ", "_").replace("'", "_");
}
$(function () {
    let playerName = "";
    // copy dungeonData
    let dungeonDataCopy = JSON.parse(JSON.stringify(dungeonData));
    // go through each dungeon, and get the coords for each room from the corresponding html map element
    for (let dungeon in dungeonDataCopy) {
        for (let room of dungeonDataCopy[dungeon]) {
            room.coords = $(`#${roomNameFixer(room.name)}`).attr('coords');
        }
    }
    // Turn on highlighting for all images with usemap attribute
    $('img[usemap]').data('maphilight', { neverOn: true });
    $('img[usemap]').maphilight();
    $('#Cave_Entrance').data('maphilight', {
        alwaysOn: true,
        neverOn: false,
        fillColor: 'FF0000',
        fillOpacity: 0.2,
        stroke: false,
        shadow: false,
        shadowRadius: 0,
        shadowColor: '000000',
        shadowOpacity: 0.2,
        shadowPosition: 'outside',
        shadowFrom: false
    }).trigger('alwaysOn.maphilight');
    $('area').each(function (i, e) {
        const coords = $(this).attr('coords').split(',');
        const room = $(this).attr('title');
        const $span = $('<span id="' + room + '-txt" class="room-text"></span>');
        $span.css({ top: coords[1] + 'px', left: coords[0] + 'px', position: 'absolute' });
        // get name of dungeon from parent name
        const dungeon = $(this).parent().attr('name').split('-')[0];
        // append to img of dungeon id (id-img)
        $(`#${dungeon}-img`).parent().append($span);
    });
    // onclick for all areas
    $('area').on('click', function (e) {
        console.log(e);
        // get parent name, split by dash, first part is id of dungeon
        const dungeon = $(this).parent().attr('name').split('-')[0];
        // get room name from title of area
        const room = $(this).attr('title');
        ws.send(`SelectRoom:${game};${dungeon};${room}`);
        e.stopPropagation();
    });
    // create websocket to localhost:8080
    const ws = new WebSocket(WS_TARGET);
    // get query parameter "game" from URL
    const urlParams = new URLSearchParams(window.location.search);
    let game = urlParams.get('game');
    let gameStarted = false;
    // if game is empty, generate a random game ID via 6 random letters
    if (!game) {
        const letters = 'abcdefghijklmnopqrstuvwxyz';
        game = '';
        for (let i = 0; i < 6; i++) {
            game += letters[Math.floor(Math.random() * letters.length)];
        }
    }
    // set game ID in URL
    window.history.replaceState({}, '', `?game=${game}`);
    // when receive message from server, log it
    ws.onmessage = function (event) {
        console.log("Received message: " + event.data);
        // split message into command and argument
        const data = event.data.split(':');
        const command = data[0];
        // join args back together
        const argsStr = data.slice(1).join(':');
        // args split by semicolon
        let args = argsStr.split(';');
        switch (command) {
            case 'StartGame':
                gameStarted = true;
            case 'UpdateState':
                const state = JSON.parse(args[0]);
                // clear all room text
                $('.room-text').text("");
                // for each player, set their name to the room they are in
                for (let player in state) {
                    const room = state[player].currentRoom;
                    // if room is empty, skip
                    if (!room)
                        continue;
                    // get room text element
                    const $roomText = document.getElementById(`${room}-txt`);
                    // set text to player name
                    $roomText.innerText = $roomText.innerText + player;
                }
                break;
        }
    };
    // when websocket is opened, send game ID to server
    ws.onopen = function () {
        // ask for name of player
        // playerName = prompt("What is your name?");
        // random name
        playerName = "Player" + Math.floor(Math.random() * 1000);
        ws.send("JoinGame:" + game + ";" + playerName);
    };
});
