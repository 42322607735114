export const dungeonData = {
    'lm': [{
            'name': 'Cave Entrance',
            'effect': 'Scry 1.',
            'previous': [],
            'leads_to': ['Goblin Lair', 'Mine Tunnels']
        }, {
            'name': 'Goblin Lair',
            'effect': 'Create a 1/1 red Goblin creature token.',
            'previous': ['Cave Entrance'],
            'leads_to': ['Storeroom', 'Dark Pool']
        }, {
            'name': 'Mine Tunnels',
            'effect': 'Create a Treasure token.',
            'previous': ['Cave Entrance'],
            'leads_to': ['Dark Pool', 'Fungi Cavern']
        }, {
            'name': 'Storeroom',
            'effect': 'Put a +1/+1 counter on target creature.',
            'previous': ['Goblin Lair'],
            'leads_to': ['Temple of Dumathoin']
        }, {
            'name': 'Dark Pool',
            'effect': 'Each opponent loses 1 life and you gain 1 life.',
            'previous': ['Goblin Lair', 'Mine Tunnels'],
            'leads_to': ['Temple of Dumathoin']
        }, {
            'name': 'Fungi Cavern',
            'effect': 'Target creature gets -4/-0 until your next turn.',
            'previous': ['Mine Tunnels'],
            'leads_to': ['Temple of Dumathoin']
        }, {
            'name': 'Temple of Dumathoin',
            'effect': 'Draw a card.',
            'previous': ['Storeroom', 'Dark Pool', 'Fungi Cavern'],
            'leads_to': []
        }],
    'dm': [{
            'name': 'Yawning Portal',
            'effect': 'You gain 1 life.',
            'previous': [],
            'leads_to': ['Dungeon Level']
        }, {
            'name': 'Dungeon Level',
            'effect': 'Scry 1.',
            'previous': ['Yawning Portal'],
            'leads_to': ['Goblin Bazaar', 'Twisted Caverns']
        }, {
            'name': 'Goblin Bazaar',
            'effect': 'Create a Treasure token.',
            'previous': ['Dungeon Level'],
            'leads_to': ['Lost Level']
        }, {
            'name': 'Twisted Caverns',
            'effect': "Target creature can't attack until your next turn.",
            'previous': ['Dungeon Level'],
            'leads_to': ['Lost Level']
        }, {
            'name': 'Lost Level',
            'effect': 'Scry 2.',
            'previous': ['Goblin Bazaar', 'Twisted Caverns'],
            'leads_to': ['Runestone Caverns', "Muiral's Graveyard"]
        }, {
            'name': 'Runestone Caverns',
            'effect': 'Exile the top two cards of your library. You may play them.',
            'previous': ['Lost Level'],
            'leads_to': ['Deep Mines']
        }, {
            'name': "Muiral's Graveyard",
            'effect': 'Create two 1/1 black Skeleton creature tokens.',
            'previous': ['Lost Level'],
            'leads_to': ['Deep Mines']
        }, {
            'name': 'Deep Mines',
            'effect': 'Scry 3.',
            'previous': ['Runestone Caverns', "Muiral's Graveyard"],
            'leads_to': ["Mad Wizard's Lair"]
        }, {
            'name': "Mad Wizard's Lair",
            'effect': 'Draw three cards and reveal them. You may cast one of them without paying its mana cost.',
            'previous': ['Deep Mines'],
            'leads_to': []
        }],
    'ta': [{
            'name': 'Trapped Entry',
            'effect': 'Each player loses 1 life.',
            'previous': [],
            'leads_to': ['Veils of Fear', 'Oubliette']
        }, {
            'name': 'Veils of Fear',
            'effect': 'Each player loses 2 life unless they discard a card.',
            'previous': ['Trapped Entry'],
            'leads_to': ['Sandfall Cell']
        }, {
            'name': 'Sandfall Cell',
            'effect': 'Each player loses 2 life unless they sacrifice an artifact, a creature, or a land.',
            'previous': ['Veils of Fear'],
            'leads_to': ['Cradle of the Death God']
        }, {
            'name': 'Oubliette',
            'effect': 'Discard a card and sacrifice an artifact, a creature, and a land.',
            'previous': ['Trapped Entry'],
            'leads_to': ['Cradle of the Death God']
        }, {
            'name': 'Cradle of the Death God',
            'effect': 'Create The Atropal, a legendary 4/4 black God Horror creature token with deathtouch.',
            'previous': ['Sandfall Cell', 'Oubliette'],
            'leads_to': []
        }],
    'uc': [{
            'name': 'Secret Entrance',
            'effect': 'Search your library for a basic land card, reveal it, put it into your hand, then shuffle.',
            'previous': [],
            'leads_to': ['Forge', 'Lost Well']
        }, {
            'name': 'Forge',
            'effect': 'Put two +1/+1 counters on target creature.',
            'previous': ['Secret Entrance'],
            'leads_to': ['Trap', 'Arena']
        }, {
            'name': 'Lost Well',
            'effect': 'Scry 2',
            'previous': ['Secret Entrance'],
            'leads_to': ['Arena', 'Stash']
        }, {
            'name': 'Trap',
            'effect': 'Target player loses 2 life.',
            'previous': ['Forge'],
            'leads_to': ['Archives']
        }, {
            'name': 'Arena',
            'effect': 'Goad target creature.',
            'previous': ['Forge', 'Lost Well'],
            'leads_to': ['Archives', 'Catacombs']
        }, {
            'name': 'Stash',
            'effect': 'Create a treasure token.',
            'previous': ['Lost Well'],
            'leads_to': ['Catacombs']
        }, {
            'name': 'Archives',
            'effect': 'Draw a card.',
            'previous': ['Trap', 'Arena'],
            'leads_to': ['Throne of the Dead Three']
        }, {
            'name': 'Catacombs',
            'effect': 'Create a 4/1 black Skeleton creature token with menace.',
            'previous': ['Arena', 'Stash'],
            'leads_to': ['Throne of the Dead Three']
        }, {
            'name': 'Throne of the Dead Three',
            'effect': 'Reveal the top card of ten cards of your library. Put a creature card from among them onto the battlefield, then put three +1/+1 counters on it. It gains hexproof until your next turn. Then shuffle.',
            'previous': ['Archives', 'Catacombs'],
            'leads_to': []
        }]
};
